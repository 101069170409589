.main-container {
    position: relative;
}
.carousel {
    /* position: absolute; */
    height: 50%;
    width: 100%;
    /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}
/* .carousel-container {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-content: center;
  height: 400px;
} */
.carousel-title {
    position: absolute;
    top: 10%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 6vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: white;
}

.carousel-subtitle {
    position: absolute;
    top: 23%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 2vw;
    font-family: 'Roboto';
    font-weight: 500;
    color: white;
}

.carousel-container > img {
  width: 100%;
  height: auto;
}

.carousel-img {
    object-position: center;
    /* object-fit: cover; */
   
}

/* .second-container {
    background-image: url('/dist/img/back-stars.png');
    background-size: cover;
} */
.news-container {
   /* background-color: whitesmoke; */
   text-align: left;

}
.news-container  .ant-card-body {
    padding: 20px;
}
/* the slides */
.news-container .slick-slide {
      margin-right: -10vw;
}
.news-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.news-slider {
    margin-left: 7%;
    overflow: hidden;
}

.news-content-title {
    font-size: 11px;
    color: grey;
    height: 15px;
    margin-bottom: 5px;
}

.news-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
    height: 60px;
    overflow: hidden;
}

.news-content-date {
    font-size: 11px;
    color: grey;
}



.events-container {
   /* background-color: whitesmoke; */
   text-align: left;

}
.events-container  .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
/* the slides */
.events-container .slick-slide {
      margin-right: -10vw;
}
.events-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.events-slider {
    margin-left: 7%;
    overflow: hidden;
}

.events-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}

.events-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
    height: 20px;
}

.events-content-date {
    font-size: 11px;
    color: grey;
}


.documents-container {
    /* background-color: whitesmoke; */
    text-align: left;

}
.documents-container .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
/* the slides */
.documents-container .slick-slide {
    margin-right: -10vw;
}
.documents-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}
.documents-slider {
    margin-left: 7%;
    overflow: hidden;
}
.document-content {
    display: flex;
    flex-direction: column;
    position: relative;
}
.document-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}
.document-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    overflow: hidden;
}
.document-content-date {
    font-size: 9px;
    color: grey;
}
.document-download-btn .btn {
    color: #EE274F;
    font-size: 11px;
    position: absolute;
    right: -7px;
    bottom: 0px;
}

/* Desktop View */
@media only screen and (min-width: 720px) {
    .homePage-main-image {
        background-position: center;
        background-size: cover;
        height: 400px;
        background-image: url("/dist/img/main-banner.jpeg");
    }
}

/* Mobile View */
@media only screen and (max-width: 720px) {
    .homePage-main-image {
        background-position: top;
        background-size: cover;
        height: 400px;
        background-image: url("/dist/img/vertical-banner.jpeg");
    }
}