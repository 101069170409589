.user-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.user-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.user-image-container {
    margin-top: 3vw;
}
.circular-user-image {
    border-radius: 50%;
    background-color: whitesmoke;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.user-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.user-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.user-role {
    font-size: 12px;
    color: grey;
}
.user-role::first-letter {
    text-transform:capitalize;
}
.user-info-divider {
    padding: 10px;
}

.user-info-column {
    border-left: solid whitesmoke 1px;
}

.user-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.user-data .ant-row {
    margin-bottom: 32px;
}

.entry-title {
    color: #959FA8;
}
.entry-data {
    color: black;
}

.entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    background-color: whitesmoke;
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.user-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.user-data .accept, .user-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.image-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}