.user-profile-container .ant-card-bordered {
    border: none;
    background-color: white;
}
.user-profile-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.user-profile-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.user-profile-image-container {
    margin-top: 3vw;
}
.circular-user-profile-image {
    border-radius: 50%;
    background-color: black;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.user-profile-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.user-profile-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.user-profile-role {
    font-size: 12px;
    color: grey;
}
.user-profile-role::first-letter {
    text-transform:capitalize;
}
.user-profile-info-divider {
    padding: 10px;
}

.user-profile-info-column {
    border-left: solid whitesmoke 1px;
}

.user-profile-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.user-profile-data .ant-row {
    margin-bottom: 32px;
}

.entry-title {
    color: #959FA8;
}
.entry-data {
    color: black;
}

.entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.user-profile-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.user-profile-data .accept, .user-profile-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.user-profile-history-title {
    margin-left: -10px;
    text-align: left;
    font-weight: bold;
    width: 80%;
}
.user-profile-history-divider {
  background-color: #1A1A1A;
  width: 1px;
  height: 40px;
  margin-left: 23px;
}
.user-profile-history-entry-title {
    color: #6C6C6C;
    font-size: 12px;
}
.user-profile-history-col {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    text-align: center;
}
