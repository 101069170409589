body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Roboto';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
  background-color: black;
  color: white;
}

#circleHollow{
background: 'transparent';
    border-radius: '50%';
    width: '20px';
     border: '2px solid #cccccc';
    height: '20px'
}
/* NavBar */
.nav-bar-header {
    background-color: #000;
    display: flex;
    justify-content: left;
    align-items: left;
}

/* .nav-bar-menu .admin-menu .ant-menu-submenu > .ant-menu {
    background-color: black;
} */

.nav-bar-menu .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 2px solid black;
}

.nav-text {
    /* font-size: 4vw; */
    font-family: 'Roboto';
    font-weight: 900;
    color: white;
}

.profile-link {
    color: white;
}

.profile-link:hover {
    color: #C00E0E;
}

.nav-bar-menu .ant-layout-header .ant-menu {
    width: 90%;
    height: 50%;
    background-color: #000;
    border-color: #000;
    background-image: url(/dist/img/navbar-background.png);
    background-position: right center;
    background-repeat: no-repeat;
    margin-top: revert;
}

.ant-menu-submenu-popup>.ant-menu {
    background-color: #C00E0E;
}

/* Mobile View */
@media only screen and (max-width: 720px) {
    .ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
        background-color: #000;
    }
}

.nav-bar-logo-image {
    width: 110px;
    height: auto;
}

/* Links */
.noAccount a {
    position: absolute;
    top: 20px;
    color: #b49c54;
}

.forgetPassword a {
    color: #b49c54;
}

.terms-conditions a {
    color: #b49c54;
}

.haveAccount a {
    color: #b49c54;
}

/* Buttons */

.login-form-button {
    width: 120px;
    background-color: #C00E0E;
    border-color: #C00E0E;
    display: flex;
    justify-content: center;
    text-align: center;
}

.login-form-button:hover,
.login-form-button:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.register-form-button {
    width: 160px;
    background-color: #C00E0E;
    border-color: #C00E0E;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.register-form-button:hover,
.register-form-button:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.submit-btn {
    font-family: "Roboto";
    font-weight: 900;
    background-color: #C00E0E;
    color: white;
    border-radius: 12px;
    width: 110px;
    height: 34px;
    margin-left: 1px;
    border-color: #C00E0E;
}

.submit-btn:hover,
.submit-btn:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.submit-btn-large {
    font-family: "Roboto";
    font-weight: 900;
    background-color: #C00E0E;
    color: white;
    border-radius: 12px;
    width: 150px;
    height: 40px;
    border-color: #C00E0E;
}

.submit-btn-large:hover,
.submit-btn-large:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}


.book-match-button {
    background-color: transparent;
    border-color: none;
    text-align: center;
}

.book-match-button:hover,
.book-match-button:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

/* Text */

.banner {
    color: white;
    position: absolute;
    left: 60px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    flex-wrap: wrap;
    grid-gap: 0px;
    gap: 0px;
}
.banner-title {
    font-size: 45px;
    font-family: 'Roboto';
    font-weight: 900;
    text-align: left;
    color: #fff;
}
.banner-subtitle {
    font-size: 24px;
    font-family: 'Roboto';
    text-align: left;
    font-weight: 800;
    margin-top: -15px;
}
.reports .banner-subtitle{
    color: #fff;
}
.main-container {
    position: relative;
}
.carousel {
    /* position: absolute; */
    height: 50%;
    width: 100%;
    /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}
/* .carousel-container {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-content: center;
  height: 400px;
} */
.carousel-title {
    position: absolute;
    top: 10%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 6vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: white;
}

.carousel-subtitle {
    position: absolute;
    top: 23%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 2vw;
    font-family: 'Roboto';
    font-weight: 500;
    color: white;
}

.carousel-container > img {
  width: 100%;
  height: auto;
}

.carousel-img {
    object-position: center;
    /* object-fit: cover; */
   
}

/* .second-container {
    background-image: url('/dist/img/back-stars.png');
    background-size: cover;
} */
.news-container {
   /* background-color: whitesmoke; */
   text-align: left;

}
.news-container  .ant-card-body {
    padding: 20px;
}
/* the slides */
.news-container .slick-slide {
      margin-right: -10vw;
}
.news-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.news-slider {
    margin-left: 7%;
    overflow: hidden;
}

.news-content-title {
    font-size: 11px;
    color: grey;
    height: 15px;
    margin-bottom: 5px;
}

.news-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
    height: 60px;
    overflow: hidden;
}

.news-content-date {
    font-size: 11px;
    color: grey;
}



.events-container {
   /* background-color: whitesmoke; */
   text-align: left;

}
.events-container  .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
/* the slides */
.events-container .slick-slide {
      margin-right: -10vw;
}
.events-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.events-slider {
    margin-left: 7%;
    overflow: hidden;
}

.events-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}

.events-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
    height: 20px;
}

.events-content-date {
    font-size: 11px;
    color: grey;
}


.documents-container {
    /* background-color: whitesmoke; */
    text-align: left;

}
.documents-container .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
/* the slides */
.documents-container .slick-slide {
    margin-right: -10vw;
}
.documents-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}
.documents-slider {
    margin-left: 7%;
    overflow: hidden;
}
.document-content {
    display: flex;
    flex-direction: column;
    position: relative;
}
.document-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}
.document-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    overflow: hidden;
}
.document-content-date {
    font-size: 9px;
    color: grey;
}
.document-download-btn .btn {
    color: #EE274F;
    font-size: 11px;
    position: absolute;
    right: -7px;
    bottom: 0px;
}

/* Desktop View */
@media only screen and (min-width: 720px) {
    .homePage-main-image {
        background-position: center;
        background-size: cover;
        height: 400px;
        background-image: url("/dist/img/main-banner.jpeg");
    }
}

/* Mobile View */
@media only screen and (max-width: 720px) {
    .homePage-main-image {
        background-position: top;
        background-size: cover;
        height: 400px;
        background-image: url("/dist/img/vertical-banner.jpeg");
    }
}
.register-layout {
    display: flex;
    justify-content: center;
    background-color: white;
}

.register-layout  .ant-upload.ant-upload-select {
    display: unset;
}

.card-layout {
    background-color: whitesmoke;
    border-radius: 40px;
    width: 95%;
    margin-top: 15px;
}

.register-img {
    border-radius: 40px;
    height: auto;
    width: 100%;
    object-fit: cover;
}

.form-title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins";
    text-align: left;
    margin-left: 66px;
    margin-top: 50px;
}

.register-form {
    margin-left: 10%;
    margin-top: 20px;
    font-family: "Poppins";
    width: 80%;
}

.register-form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
}

.noAccount {
    position: relative;
    text-align: left;
}

.upload-logo .ant-upload-list {
    display: none;
}
.black-upload {
    color: grey
}
.login-layout {
    display: flex;
    justify-content: center;
    background-color: white;
}

.card-layout {
    background-color: whitesmoke;
    border-radius: 40px;
    width: 95%;
    margin-top: 15px;
}

.login-img {
    border-radius: 40px;
    height: auto;
    width: 100%;
    object-fit: cover;
}

.form-title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins";
    text-align: left;
    margin-left: 66px;
    margin-top: 50px;
}

.login-form {
    margin-left: 10%;
    margin-top: 20px;
    font-family: "Poppins";
    width: 80%;
}

.login-form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
}

.noAccount {
    position: relative;
    text-align: left;
}
.reservations-container .ant-card-bordered {
    border: none;
}

.matches-table {
    padding: 30px;
    background-color: white;
    overflow: scroll;
}
.ant-table table { 
    border-spacing: 0 12px;
    box-shadow: 2px;
    box-sizing: border-box;
    border-radius: 12px;
}

.ant-table-thead > tr > th {
    color: grey;
    background: white;
}

.circular-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.reservations-details .ant-col{
    margin-bottom: 12px;
}
.reservations-details .qrcode-seat {
    background-color: white;
    width: 210px;
    border-radius: 12px;
    height: 210px;
    padding: 10px;
}
.reservations-details .seat {
    height: 40px;
}
.reservations-details .seat-title {
    font-weight: 100;
}
.reservations-details .seat-number {
    font-weight: bold;
}
.reservations-details .qrcode {
    margin-top: 5px;
}
.pitch-main-container {
    display: grid;
    place-items: center;
    overflow: auto;
    padding: 10px
}
.map-container {
    background-image: url("/dist/img/court.png");
    width: 734px;
    height: 501px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.seat-selection-col {
    padding: 30px;
}
.seat-section-title {
    font-weight: bold;
    font-size: 18px;
}
.seat-section-subtitle {
    font-size: 14px;
    margin-bottom: 5px;
}
.seat-number-view .ant-input{
    width: 110px;
    border-radius: 6px;
    text-align: center;
}
.bullet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.main-container {
    position: relative;
    overflow: hidden;
}
.centered {
  position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto;
}
img.blurr {
  z-index: 1;
    -webkit-filter: blur(9px);
    -o-filter: blur(9px);
    filter: blur(9px);
}

.carousel {
    /* position: absolute; */
    height: 50%;
    width: 100%;
    /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}
.carousel-container {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-content: center;
}
.carousel-title {
    position: absolute;
    top: 10%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 6vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: white;
}

.carousel-subtitle {
    position: absolute;
    top: 23%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 2vw;
    font-family: 'Roboto';
    font-weight: 500;
    color: white;
}

.carousel-container > img {
  width: 100%;
  height: auto;
}

.carousel-img {
    object-position: center;
    /* object-fit: scale-down; */
   
}

.event-info {
    background-color: white;
    padding-left: 7%;
    padding-bottom: 3%;
    padding-top: 3%;
    text-align: left;
   
}
.event-main-title {
    color: gray;
    margin-bottom: 8px;
     
}

.event-description {
    width: 80%;
    margin-bottom: 16px;
}

.event-documents {
   background-color: whitesmoke;
   text-align: left;
   margin-top: 14px;
}
.event-documents  .ant-card-body {
    padding-left: 20px;
    padding-top: 5px;
}
/* the slides */
.event-documents .slick-slide {
      margin-right: -10vw;
}
.event-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.event-document-no-data {
    margin-left: 7%;
    margin-top: 12px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 200;
    color: grey;
}

.event-slider {
    margin-left: 7%;
    margin-top: 8px;
    overflow: hidden;
}

.event-content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.event-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}

.event-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    overflow: hidden;
}

.event-content-date {
    font-size: 9px;
    color: grey;
}

.event-download-btn .btn {
    color: #EE274F;
    font-size: 11px;
    position: absolute;
    right: -7px;
    bottom: 0px;
}


.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.event-star {
    color: #EE274F;
    position: absolute;
    top: -10px;
    right: -5px;
}
.ant-popover-inner {
    background-color: black;
    border-radius: 12px;
}

.ant-picker-cell .ant-picker-cell-inner {
    border-radius: 14px;
    
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EE274F;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #EE274F;
     border-radius: 14px;
}
.event-badge .ant-badge-status-text {
    color: white;
}

.popover-content-day {
    color: #EE274F;
    font-size: 11px;
}
.popover-content-date {
    color: #fff;
    font-size: 17px;
    margin-bottom: 16px;
    font-family: 'Roboto';
    font-weight: 600;
}
.event-page-container {
   background-color: whitesmoke;
   text-align: left;
   padding: 50px;
   /* display: flex;
   justify-content: center; */
}
.event-page-container  .ant-col {
    padding-left: 4rem;
}
/* Mobile View */
@media only screen and (max-width: 720px) {
    .event-page-container .ant-col {
        padding-left: 25px;
    }
}

.event-page-container  .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
/* the slides */
.event-page-container .slick-slide {
      margin-right: -10vw;
}
.event-page-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.event-page-slider {
    margin-left: 7%;
    overflow: hidden;
}

.event-page-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
        height: 15px;

}

.event-page-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
    height: 20px;
}

.event-page-content-date {
    font-size: 11px;
    color: grey;
}

.document-container {
    background-color: whitesmoke;
    text-align: left;
    padding: 50px;
    /* display: flex;
    justify-content: center; */
}
.document-container .ant-col {
    padding-left: 4rem;
}
/* Mobile View */
@media only screen and (max-width: 720px) {
    .document-container .ant-col {
        padding-left: 25px;
    }
}
.document-container .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
.document-container .slick-slide {
    margin-right: -10vw;
}
.document-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}
.document-slider {
    margin-left: 7%;
    overflow: hidden;
}
.document-content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.document-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}
.document-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    overflow: hidden;
}
.document-content-date {
    font-size: 9px;
    color: grey;
}
.event-download-btn .btn {
    color: #EE274F;
    font-size: 11px;
    position: absolute;
    right: -7px;
    bottom: 0px;
}
html {
    scroll-behavior: smooth;
}
.main-privacy-policy-container {
    padding: 30px;
    vertical-align: top;
    text-align: left;
}
.main-privacy-policy-title {
    font-size: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-weight: 600;
}
.policy-container {
    background-color: white;
    text-align: left;
    border-radius: 18px;
    padding: 15px;
}

.policy-container ol {
    font-size: 24px;
}
.subtitle-terms-of-use {
    font-size: 24px;
    padding: 10px
}
.logo {
  margin-right: 2rem;
}

a.link:hover {
  /*Any style you want to have link on mouse over*/
  color: black;
}
/* .admin-menu, .ant-menu-horizontal {
    border: 0;
    border-bottom: 1px solid #fff;
    box-shadow: none;
} */
/* .admin-menu .ant-menu-submenu-popup {
  top: 64px !important;
} */

.avatar-container {
  display: inline-flex;
  font-family: 'Roboto';
  font-weight: 900;
  margin-left: 10px;
 }
.avatar-divider {
  background-color: white;
  width: 1.5px;
  height: 40%;
  margin-top: 19px;
  margin-left: 8px;
  margin-right: 8px;
 }
.loggedin-avatar {
  margin-top: 15px;
}

.nav-bar-menu .ant-layout-header .ant-menu {
  display: block;
  line-height: 30px;
}
.user-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.user-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.user-image-container {
    margin-top: 3vw;
}
.circular-user-image {
    border-radius: 50%;
    background-color: whitesmoke;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.user-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.user-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.user-role {
    font-size: 12px;
    color: grey;
}
.user-role::first-letter {
    text-transform:capitalize;
}
.user-info-divider {
    padding: 10px;
}

.user-info-column {
    border-left: solid whitesmoke 1px;
}

.user-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.user-data .ant-row {
    margin-bottom: 32px;
}

.entry-title {
    color: #959FA8;
}
.entry-data {
    color: black;
}

.entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    background-color: whitesmoke;
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.user-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.user-data .accept, .user-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.image-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.done-img {
    width: 170px; 
    height: auto;
    margin-bottom: 40px;
}
.main-title {
    font-weight: 900;
    color: black;
}
.subtitle {
    margin-bottom: 20px;
    color: grey;
}
.user-profile-container .ant-card-bordered {
    border: none;
    background-color: white;
}
.user-profile-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.user-profile-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.user-profile-image-container {
    margin-top: 3vw;
}
.circular-user-profile-image {
    border-radius: 50%;
    background-color: black;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.user-profile-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.user-profile-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.user-profile-role {
    font-size: 12px;
    color: grey;
}
.user-profile-role::first-letter {
    text-transform:capitalize;
}
.user-profile-info-divider {
    padding: 10px;
}

.user-profile-info-column {
    border-left: solid whitesmoke 1px;
}

.user-profile-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.user-profile-data .ant-row {
    margin-bottom: 32px;
}

.entry-title {
    color: #959FA8;
}
.entry-data {
    color: black;
}

.entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.user-profile-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.user-profile-data .accept, .user-profile-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.user-profile-history-title {
    margin-left: -10px;
    text-align: left;
    font-weight: bold;
    width: 80%;
}
.user-profile-history-divider {
  background-color: #1A1A1A;
  width: 1px;
  height: 40px;
  margin-left: 23px;
}
.user-profile-history-entry-title {
    color: #6C6C6C;
    font-size: 12px;
}
.user-profile-history-col {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    text-align: center;
}

.contacts-container .ant-card-bordered {
    border: none;
}
.description {
    white-space: pre-line;
    padding: 20px;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.topic-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}

.form-main-title {
    /* padding: 60px 60px 20px 60px; */
    padding-top: 4vh;
    margin-bottom: -60px;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    
}
.social-media-title {
    /* padding: 60px 60px 20px 60px; */
    padding-top: 4vh;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding-right: 7px;
    
}
.location-title {
    padding-top: 5vh;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding-right: 7px;
    
}
.hours-title {
    padding-top: 10vh;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding-right: 7px;
}
.sub-text {
    color: grey;
}
.emails-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}
.notifications-container .rdw-editor-main {
  height: 350px;
  padding-left: 5px;
  padding-right: 5px;
  overflow: auto;
  box-sizing: border-box;
  background-color: white;
  font-weight: normal;
}
.tabs-table-container {
    padding: 5px;
    background-color: white;
    overflow: scroll;
}
.tabs-table table {
    text-align: left;
    font-weight: 200;
}
.table-actions {
    width: 100%;
}
.table-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.accept, .reject{
    width: 74px;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.circular-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.table-name-btn {
    color: black
}

.rejection-modal .ant-modal-content, .rejection-modal .ant-modal-header  {
    border-radius: 12px;
}
.rejection-modal-text {
    margin-bottom: 20px;
    font-size: 14px;
}
.rejection-modal-error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
.admin-sider .ant-layout-sider-trigger{
    position: inherit;
}

.content-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: left;
    margin: 16px 0;
}

.sider-title {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    padding-top: 4px;
    font-weight: bolder;
    vertical-align: text-bottom;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 16px;
}
.news-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: scale-down;
  object-position: center;
}

.teams-form, .ant-form-item-extra {
  text-align: left;
  font-weight: lighter;
  font-size: 11px;
}
.logo-uploader .ant-upload.ant-upload-select-picture-card {
  width: 120px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: scale-down;
  object-position: center;
}
.capital-cities {
  text-transform: capitalize;
}

.tournament-date .ant-picker-range {
  width: 100%;
}

.accreditation-radio .ant-form-item-control-input-content {
  text-align: left;
}

.content-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: left;
    margin: 16px 0;
}



.news-table-image {
    width: 60px;
    height: auto;
    object-fit: contain;
}
.table-icons {
   margin-right: 15px;
}
.table-top-bar {
    display: flex;
    justify-content: end;
    flex-direction: row;
    padding-right: 30px;
    margin-top: 10px;
}
.table-container {
    padding: 8px;
    background-color: white;
    border-top-right-radius: 8px;
     border-top-left-radius: 8px;
}
.news-table table {
    text-align: left;
    font-weight: 200;
    font-size: 13px;
}
.news-addbtn {
    border-radius: 5px;
    margin-left: 10px;
}
.news-search .ant-input{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.news-search .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.news-admin .rdw-editor-main {
    height: 350px;
    padding-left: 5px;
    padding-right: 5px;
    overflow: auto;
    box-sizing: border-box;
    background-color: white;
    font-weight: normal;
}
.entry-input {
    width: 60%;
}
.entry-image {
    width: 30%;
    height: 50%;
}
.edit-user-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.edit-user-select {
    width: 60%;
    text-align: left;
}
.edit-user-avatar-uploader .ant-upload.ant-upload-select-picture-card {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: scale-down;
    object-position: center;
}
.circular-edit-user-profile-image {
    height: 110px;
}
.reports-container .ant-card-bordered {
    border: none;
}
.reports-card {
    width: 100%;
    background-color: black;
    height: 380px;
    background-image: url('/dist/img/banner-3.jpeg');
    background-position: center;
    background-size: cover;
}

.reports-news-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}

.reports-news-table-image {
    width: 60px;
    height: auto;
    object-fit: contain;
}
.reports-table-icons {
   margin-right: 15px;
}
.reports-table-top-bar {
    display: flex;
    justify-content: end;
    flex-direction: row;
    padding-right: 30px;
    margin-top: 10px;
}
.reports-table-container {
    padding: 30px;
    background-color: white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.reports-news-table table {
    text-align: left;
    font-weight: 200;
    font-size: 13px;
}
.reports-news-addbtn {
    border-radius: 5px;
    margin-left: 10px;
}
.reports-news-search .ant-input{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.reports-news-search .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.reports-table-actions {
    width: 100%;
}
.edit-reports-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.edit-reports-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.edit-reports-image-container {
    margin-top: 3vw;
}
.circular-edit-reports-image {
    border-radius: 50%;
    background-color: black;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.edit-reports-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.edit-reports-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.edit-reports-role {
    font-size: 12px;
    color: grey;
}
.edit-reports-role::first-letter {
    text-transform:capitalize;
}
.edit-reports-info-divider {
    padding: 10px;
}

.edit-reports-info-column {
    border-left: solid whitesmoke 1px;
}

.edit-reports-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.edit-reports-data .ant-row {
    margin-bottom: 32px;
}

.edit-reports-entry-title {
    color: #959FA8;
}
.edit-reports-entry-data {
    color: black;
}

.edit-reports-entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.edit-reports-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.edit-reports-data .accept, .edit-reports-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.edit-reports-history-title {
    margin-left: -10px;
    text-align: left;
    font-weight: bold;
    width: 80%;
}
.edit-reports-history-divider {
  background-color: #1A1A1A;
  width: 1px;
  height: 40px;
  margin-left: 23px;
}
.edit-reports-history-entry-title {
    color: #6C6C6C;
    font-size: 12px;
}
.edit-reports-history-col {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    text-align: center;
}



.edit-reports-form {
    padding: 0px 30px 30px 30px;
    /* font-family: "Roboto"; */
    /* font-weight: 900; */
}

.edit-reports-attachments {
    text-align: left;
    margin-bottom: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
}
.edit-reports-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.view-reports-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.view-reports-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.view-reports-image-container {
    margin-top: 3vw;
}
.circular-view-reports-image {
    border-radius: 50%;
    background-color: black;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.view-reports-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.view-reports-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.view-reports-role {
    font-size: 12px;
    color: grey;
}
.view-reports-role::first-letter {
    text-transform:capitalize;
}
.view-reports-info-divider {
    padding: 10px;
}

.view-reports-info-column {
    border-left: solid whitesmoke 1px;
}

.view-reports-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.view-reports-data .ant-row {
    margin-bottom: 32px;
}

.view-reports-entry-title {
    color: #959FA8;
}
.view-reports-entry-data {
    color: black;
}

.view-reports-entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.view-reports-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.view-reports-data .accept, .view-reports-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.view-reports-history-title {
    margin-left: -10px;
    text-align: left;
    font-weight: bold;
    width: 80%;
}
.view-reports-history-divider {
  background-color: #1A1A1A;
  width: 1px;
  height: 40px;
  margin-left: 23px;
}
.view-reports-history-entry-title {
    color: #6C6C6C;
    font-size: 12px;
}
.view-reports-history-col {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    text-align: center;
}

.view-reports-questions {
    text-align: left;
    margin-bottom: 30px;
    margin-left: 20px;
}

.view-reports-attachments {
    text-align: center;
    margin-bottom: 30px;
    margin-left: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
   
}
.view-reports-img {
    width: 140px;
    height: 140px;
    object-fit: cover;
}

