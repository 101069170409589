.emails-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}
.notifications-container .rdw-editor-main {
  height: 350px;
  padding-left: 5px;
  padding-right: 5px;
  overflow: auto;
  box-sizing: border-box;
  background-color: white;
  font-weight: normal;
}