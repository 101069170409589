.pitch-main-container {
    display: grid;
    place-items: center;
    overflow: auto;
    padding: 10px
}
.map-container {
    background-image: url("/dist/img/court.png");
    width: 734px;
    height: 501px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.seat-selection-col {
    padding: 30px;
}
.seat-section-title {
    font-weight: bold;
    font-size: 18px;
}
.seat-section-subtitle {
    font-size: 14px;
    margin-bottom: 5px;
}
.seat-number-view .ant-input{
    width: 110px;
    border-radius: 6px;
    text-align: center;
}
.bullet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}