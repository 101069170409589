/* NavBar */
.nav-bar-header {
    background-color: #000;
    display: flex;
    justify-content: left;
    align-items: left;
}

/* .nav-bar-menu .admin-menu .ant-menu-submenu > .ant-menu {
    background-color: black;
} */

.nav-bar-menu .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 2px solid black;
}

.nav-text {
    /* font-size: 4vw; */
    font-family: 'Roboto';
    font-weight: 900;
    color: white;
}

.profile-link {
    color: white;
}

.profile-link:hover {
    color: #C00E0E;
}

.nav-bar-menu .ant-layout-header .ant-menu {
    width: 90%;
    height: 50%;
    background-color: #000;
    border-color: #000;
    background-image: url(/dist/img/navbar-background.png);
    background-position: right center;
    background-repeat: no-repeat;
    margin-top: revert;
}

.ant-menu-submenu-popup>.ant-menu {
    background-color: #C00E0E;
}

/* Mobile View */
@media only screen and (max-width: 720px) {
    .ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
        background-color: #000;
    }
}

.nav-bar-logo-image {
    width: 110px;
    height: auto;
}

/* Links */
.noAccount a {
    position: absolute;
    top: 20px;
    color: #b49c54;
}

.forgetPassword a {
    color: #b49c54;
}

.terms-conditions a {
    color: #b49c54;
}

.haveAccount a {
    color: #b49c54;
}

/* Buttons */

.login-form-button {
    width: 120px;
    background-color: #C00E0E;
    border-color: #C00E0E;
    display: flex;
    justify-content: center;
    text-align: center;
}

.login-form-button:hover,
.login-form-button:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.register-form-button {
    width: 160px;
    background-color: #C00E0E;
    border-color: #C00E0E;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.register-form-button:hover,
.register-form-button:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.submit-btn {
    font-family: "Roboto";
    font-weight: 900;
    background-color: #C00E0E;
    color: white;
    border-radius: 12px;
    width: 110px;
    height: 34px;
    margin-left: 1px;
    border-color: #C00E0E;
}

.submit-btn:hover,
.submit-btn:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.submit-btn-large {
    font-family: "Roboto";
    font-weight: 900;
    background-color: #C00E0E;
    color: white;
    border-radius: 12px;
    width: 150px;
    height: 40px;
    border-color: #C00E0E;
}

.submit-btn-large:hover,
.submit-btn-large:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}


.book-match-button {
    background-color: transparent;
    border-color: none;
    text-align: center;
}

.book-match-button:hover,
.book-match-button:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

/* Text */

.banner {
    color: white;
    position: absolute;
    left: 60px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    flex-wrap: wrap;
    gap: 0px;
}
.banner-title {
    font-size: 45px;
    font-family: 'Roboto';
    font-weight: 900;
    text-align: left;
    color: #fff;
}
.banner-subtitle {
    font-size: 24px;
    font-family: 'Roboto';
    text-align: left;
    font-weight: 800;
    margin-top: -15px;
}
.reports .banner-subtitle{
    color: #fff;
}