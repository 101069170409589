.reservations-container .ant-card-bordered {
    border: none;
}

.matches-table {
    padding: 30px;
    background-color: white;
    overflow: scroll;
}
.ant-table table { 
    border-spacing: 0 12px;
    box-shadow: 2px;
    box-sizing: border-box;
    border-radius: 12px;
}

.ant-table-thead > tr > th {
    color: grey;
    background: white;
}

.circular-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.reservations-details .ant-col{
    margin-bottom: 12px;
}
.reservations-details .qrcode-seat {
    background-color: white;
    width: 210px;
    border-radius: 12px;
    height: 210px;
    padding: 10px;
}
.reservations-details .seat {
    height: 40px;
}
.reservations-details .seat-title {
    font-weight: 100;
}
.reservations-details .seat-number {
    font-weight: bold;
}
.reservations-details .qrcode {
    margin-top: 5px;
}