.news-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: scale-down;
  object-position: center;
}

.teams-form, .ant-form-item-extra {
  text-align: left;
  font-weight: lighter;
  font-size: 11px;
}
.logo-uploader .ant-upload.ant-upload-select-picture-card {
  width: 120px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: scale-down;
  object-position: center;
}
.capital-cities {
  text-transform: capitalize;
}

.tournament-date .ant-picker-range {
  width: 100%;
}

.accreditation-radio .ant-form-item-control-input-content {
  text-align: left;
}

.content-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: left;
    margin: 16px 0;
}



.news-table-image {
    width: 60px;
    height: auto;
    object-fit: contain;
}
.table-icons {
   margin-right: 15px;
}
.table-top-bar {
    display: flex;
    justify-content: end;
    flex-direction: row;
    padding-right: 30px;
    margin-top: 10px;
}
.table-container {
    padding: 8px;
    background-color: white;
    border-top-right-radius: 8px;
     border-top-left-radius: 8px;
}
.news-table table {
    text-align: left;
    font-weight: 200;
    font-size: 13px;
}
.news-addbtn {
    border-radius: 5px;
    margin-left: 10px;
}
.news-search .ant-input{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.news-search .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.news-admin .rdw-editor-main {
    height: 350px;
    padding-left: 5px;
    padding-right: 5px;
    overflow: auto;
    box-sizing: border-box;
    background-color: white;
    font-weight: normal;
}