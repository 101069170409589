.event-page-container {
   background-color: whitesmoke;
   text-align: left;
   padding: 50px;
   /* display: flex;
   justify-content: center; */
}
.event-page-container  .ant-col {
    padding-left: 4rem;
}
/* Mobile View */
@media only screen and (max-width: 720px) {
    .event-page-container .ant-col {
        padding-left: 25px;
    }
}

.event-page-container  .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
/* the slides */
.event-page-container .slick-slide {
      margin-right: -10vw;
}
.event-page-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.event-page-slider {
    margin-left: 7%;
    overflow: hidden;
}

.event-page-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
        height: 15px;

}

.event-page-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
    height: 20px;
}

.event-page-content-date {
    font-size: 11px;
    color: grey;
}
