.login-layout {
    display: flex;
    justify-content: center;
    background-color: white;
}

.card-layout {
    background-color: whitesmoke;
    border-radius: 40px;
    width: 95%;
    margin-top: 15px;
}

.login-img {
    border-radius: 40px;
    height: auto;
    width: 100%;
    object-fit: cover;
}

.form-title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins";
    text-align: left;
    margin-left: 66px;
    margin-top: 50px;
}

.login-form {
    margin-left: 10%;
    margin-top: 20px;
    font-family: "Poppins";
    width: 80%;
}

.login-form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
}

.noAccount {
    position: relative;
    text-align: left;
}