.admin-sider .ant-layout-sider-trigger{
    position: inherit;
}

.content-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: left;
    margin: 16px 0;
}

.sider-title {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    padding-top: 4px;
    font-weight: bolder;
    vertical-align: text-bottom;
    height: fit-content;
    margin: 16px;
}