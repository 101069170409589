.document-container {
    background-color: whitesmoke;
    text-align: left;
    padding: 50px;
    /* display: flex;
    justify-content: center; */
}
.document-container .ant-col {
    padding-left: 4rem;
}
/* Mobile View */
@media only screen and (max-width: 720px) {
    .document-container .ant-col {
        padding-left: 25px;
    }
}
.document-container .ant-card-body {
    padding-left: 20px;
    padding-top: 10px;
}
.document-container .slick-slide {
    margin-right: -10vw;
}
.document-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 4vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}
.document-slider {
    margin-left: 7%;
    overflow: hidden;
}
.document-content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.document-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}
.document-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    overflow: hidden;
}
.document-content-date {
    font-size: 9px;
    color: grey;
}
.event-download-btn .btn {
    color: #EE274F;
    font-size: 11px;
    position: absolute;
    right: -7px;
    bottom: 0px;
}