html {
    scroll-behavior: smooth;
}
.main-privacy-policy-container {
    padding: 30px;
    vertical-align: top;
    text-align: left;
}
.main-privacy-policy-title {
    font-size: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-weight: 600;
}
.policy-container {
    background-color: white;
    text-align: left;
    border-radius: 18px;
    padding: 15px;
}

.policy-container ol {
    font-size: 24px;
}
.subtitle-terms-of-use {
    font-size: 24px;
    padding: 10px
}