.main-container {
    position: relative;
    overflow: hidden;
}
.centered {
  position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto;
}
img.blurr {
  z-index: 1;
    -webkit-filter: blur(9px);
    -o-filter: blur(9px);
    filter: blur(9px);
}

.carousel {
    /* position: absolute; */
    height: 50%;
    width: 100%;
    /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}
.carousel-container {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-content: center;
}
.carousel-title {
    position: absolute;
    top: 10%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 6vw;
    font-family: 'Roboto';
    font-weight: 900;
    color: white;
}

.carousel-subtitle {
    position: absolute;
    top: 23%;
    left: 6%;
    /* font-size: 83px; */
    font-size: 2vw;
    font-family: 'Roboto';
    font-weight: 500;
    color: white;
}

.carousel-container > img {
  width: 100%;
  height: auto;
}

.carousel-img {
    object-position: center;
    /* object-fit: scale-down; */
   
}

.event-info {
    background-color: white;
    padding-left: 7%;
    padding-bottom: 3%;
    padding-top: 3%;
    text-align: left;
   
}
.event-main-title {
    color: gray;
    margin-bottom: 8px;
     
}

.event-description {
    width: 80%;
    margin-bottom: 16px;
}

.event-documents {
   background-color: whitesmoke;
   text-align: left;
   margin-top: 14px;
}
.event-documents  .ant-card-body {
    padding-left: 20px;
    padding-top: 5px;
}
/* the slides */
.event-documents .slick-slide {
      margin-right: -10vw;
}
.event-title {
    margin-left: 7%;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 900;
    color: black;
}

.event-document-no-data {
    margin-left: 7%;
    margin-top: 12px;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 200;
    color: grey;
}

.event-slider {
    margin-left: 7%;
    margin-top: 8px;
    overflow: hidden;
}

.event-content {
    display: flex;
    flex-direction: column;
    position: relative;
}

.event-content-title {
    font-size: 11px;
    color: grey;
    margin-bottom: 5px;
}

.event-content-description {
    font-size: 12px;
    color: black;
    font-weight: 900;
    overflow: hidden;
}

.event-content-date {
    font-size: 9px;
    color: grey;
}

.event-download-btn .btn {
    color: #EE274F;
    font-size: 11px;
    position: absolute;
    right: -7px;
    bottom: 0px;
}


.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.event-star {
    color: #EE274F;
    position: absolute;
    top: -10px;
    right: -5px;
}
.ant-popover-inner {
    background-color: black;
    border-radius: 12px;
}

.ant-picker-cell .ant-picker-cell-inner {
    border-radius: 14px;
    
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EE274F;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #EE274F;
     border-radius: 14px;
}
.event-badge .ant-badge-status-text {
    color: white;
}

.popover-content-day {
    color: #EE274F;
    font-size: 11px;
}
.popover-content-date {
    color: #fff;
    font-size: 17px;
    margin-bottom: 16px;
    font-family: 'Roboto';
    font-weight: 600;
}