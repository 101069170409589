.contacts-container .ant-card-bordered {
    border: none;
}
.description {
    white-space: pre-line;
    padding: 20px;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.topic-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}

.form-main-title {
    /* padding: 60px 60px 20px 60px; */
    padding-top: 4vh;
    margin-bottom: -60px;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    
}
.social-media-title {
    /* padding: 60px 60px 20px 60px; */
    padding-top: 4vh;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding-right: 7px;
    
}
.location-title {
    padding-top: 5vh;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding-right: 7px;
    
}
.hours-title {
    padding-top: 10vh;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding-right: 7px;
}
.sub-text {
    color: grey;
}