.logo {
  margin-right: 2rem;
}

a.link:hover {
  /*Any style you want to have link on mouse over*/
  color: black;
}
/* .admin-menu, .ant-menu-horizontal {
    border: 0;
    border-bottom: 1px solid #fff;
    box-shadow: none;
} */
/* .admin-menu .ant-menu-submenu-popup {
  top: 64px !important;
} */

.avatar-container {
  display: inline-flex;
  font-family: 'Roboto';
  font-weight: 900;
  margin-left: 10px;
 }
.avatar-divider {
  background-color: white;
  width: 1.5px;
  height: 40%;
  margin-top: 19px;
  margin-left: 8px;
  margin-right: 8px;
 }
.loggedin-avatar {
  margin-top: 15px;
}

.nav-bar-menu .ant-layout-header .ant-menu {
  display: block;
  line-height: 30px;
}