.tabs-table-container {
    padding: 5px;
    background-color: white;
    overflow: scroll;
}
.tabs-table table {
    text-align: left;
    font-weight: 200;
}
.table-actions {
    width: 100%;
}
.table-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.accept, .reject{
    width: 74px;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.circular-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.table-name-btn {
    color: black
}

.rejection-modal .ant-modal-content, .rejection-modal .ant-modal-header  {
    border-radius: 12px;
}
.rejection-modal-text {
    margin-bottom: 20px;
    font-size: 14px;
}
.rejection-modal-error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}