.reports-container .ant-card-bordered {
    border: none;
}
.reports-card {
    width: 100%;
    background-color: black;
    height: 380px;
    background-image: url('/dist/img/banner-3.jpeg');
    background-position: center;
    background-size: cover;
}

.reports-news-select {
  width: 100%;
  font-weight: 300;
  text-align: left;
}

.reports-news-table-image {
    width: 60px;
    height: auto;
    object-fit: contain;
}
.reports-table-icons {
   margin-right: 15px;
}
.reports-table-top-bar {
    display: flex;
    justify-content: end;
    flex-direction: row;
    padding-right: 30px;
    margin-top: 10px;
}
.reports-table-container {
    padding: 30px;
    background-color: white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.reports-news-table table {
    text-align: left;
    font-weight: 200;
    font-size: 13px;
}
.reports-news-addbtn {
    border-radius: 5px;
    margin-left: 10px;
}
.reports-news-search .ant-input{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.reports-news-search .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.reports-table-actions {
    width: 100%;
}