.entry-input {
    width: 60%;
}
.entry-image {
    width: 30%;
    height: 50%;
}
.edit-user-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.edit-user-select {
    width: 60%;
    text-align: left;
}
.edit-user-avatar-uploader .ant-upload.ant-upload-select-picture-card {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: scale-down;
    object-position: center;
}
.circular-edit-user-profile-image {
    height: 110px;
}