.done-img {
    width: 170px; 
    height: auto;
    margin-bottom: 40px;
}
.main-title {
    font-weight: 900;
    color: black;
}
.subtitle {
    margin-bottom: 20px;
    color: grey;
}