.view-reports-data-container {
    background-color: white;   
    padding: 30px 10px 10px 10px;
}
.view-reports-info-header {
    text-align: left;
    font-weight: bold;
    width: 80%;
    padding: 5px 5px 5px 30px;
}

.view-reports-image-container {
    margin-top: 3vw;
}
.circular-view-reports-image {
    border-radius: 50%;
    background-color: black;
    width: 110px;
    height: 110px;
    margin-bottom: 8px;
}
.view-reports-data-container .ant-image-mask {
    border-radius: 50%;
    background-color: transparent;
}
.view-reports-name {
    font-weight: bold;
    font-size: 16px;
    color: black;
}
.view-reports-role {
    font-size: 12px;
    color: grey;
}
.view-reports-role::first-letter {
    text-transform:capitalize;
}
.view-reports-info-divider {
    padding: 10px;
}

.view-reports-info-column {
    border-left: solid whitesmoke 1px;
}

.view-reports-data {
    padding: 5px 5px 5px 30px;
    text-align: left;
}
.view-reports-data .ant-row {
    margin-bottom: 32px;
}

.view-reports-entry-title {
    color: #959FA8;
}
.view-reports-entry-data {
    color: black;
}

.view-reports-entry-data-cptl::first-letter {
    text-transform:capitalize;
}
.document-img {
    width: auto;
    height: 80px;
    max-width: 120px;
    max-height: 80px;
}

.view-reports-data-btn {
    background-color: black;
    color: white;
    border-radius: 6px;
}
.view-reports-data .accept, .view-reports-data .reject{
    width: 124px;
    margin-right: 2vw;
}

.accept:hover, .accept:focus .accept:active{
    background-color: green;
    border-color: green;
    color: white;
}
.reject:hover, .reject:focus .reject:active{
    background-color: red;
    border-color: red;
    color: white;
}
.view-reports-history-title {
    margin-left: -10px;
    text-align: left;
    font-weight: bold;
    width: 80%;
}
.view-reports-history-divider {
  background-color: #1A1A1A;
  width: 1px;
  height: 40px;
  margin-left: 23px;
}
.view-reports-history-entry-title {
    color: #6C6C6C;
    font-size: 12px;
}
.view-reports-history-col {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    text-align: center;
}

.view-reports-questions {
    text-align: left;
    margin-bottom: 30px;
    margin-left: 20px;
}

.view-reports-attachments {
    text-align: center;
    margin-bottom: 30px;
    margin-left: 20px;
    width: fit-content;
    height: fit-content;
    justify-content: center;
   
}
.view-reports-img {
    width: 140px;
    height: 140px;
    object-fit: cover;
}
